// DS V2
import React from 'react';
import { Typography } from '@ds';
import { InformationCircleIcon } from '@heroicons/react/solid';
import capitalize from 'lodash/capitalize';
import { useRouter } from 'next/router';
import {
  RegistryImportState,
  useRegistryImportStatusQuery,
} from '@/apollo/generated';
import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';
import { isUpsellingRegistry } from '@/hooks/use-registry-not-supported-redirect';

const WelcomeSection: React.FC<{ title: string }> = ({ title }) => {
  const router = useRouter();
  const { isComputershare, isPremium } = useCurrentCompanyProfileUser();

  const { data } = useRegistryImportStatusQuery();

  const NoRegistryBanner = () => {
    // Show banner on premium product and if they are either a Computershare client or not importing
    const showBanner =
      isPremium &&
      (!data?.registryImportStatus?.state ||
        data?.registryImportStatus?.state === RegistryImportState.None ||
        isComputershare);

    if (showBanner) {
      const message = isComputershare
        ? `Integration with Computershare is not supported. We support integrations with multiple other registries, and can assist in transitioning you to a registry that is supported. Contact your Client Success Manager to know more.`
        : `We are currently awaiting data integration from ${capitalize(
            data?.registryImportStatus?.registry
          )}. Please contact your Client Success Manager if you have persistent issues.`;

      return (
        <div className="p-4 sm:p-0">
          <div className="mb-6 flex items-center gap-2 rounded-md bg-chart-blue-light-2 p-5">
            <div className="shrink">
              <InformationCircleIcon
                className="fill-blue-700"
                height={18}
                width={18}
              />
            </div>
            <div className="flex grow flex-wrap items-center justify-between gap-2 lg:flex-nowrap">
              <Typography className="text-blue-700">{message}</Typography>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="p-4 sm:p-0">
      {!isComputershare && !isUpsellingRegistry(router.pathname) && (
        <NoRegistryBanner />
      )}
      <div className="sm:flex sm:items-center sm:justify-between">
        <div className="mb-2 sm:mb-0">
          <Typography testId="dashboard-welcome-msg" variant="text-display-md">
            {title}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default WelcomeSection;

// DS V2
import React from 'react';
import { Typography } from '@ds';

interface Props {
  blurContent?: React.ReactNode;
  bottomContent?: React.ReactNode;
  children?: React.ReactNode;
  noContentOnlyTitle?: boolean;
  rightTitle?: React.ReactNode;
  subtitle?: string;
  title?: string;
  titleOutsideBorder?: boolean;

  topContent?: React.ReactNode;
}

const CardWithTitles: React.FC<Props> = ({
  blurContent,
  bottomContent,
  children,
  noContentOnlyTitle,
  rightTitle,
  subtitle,
  title,
  titleOutsideBorder = false,
  topContent,
}) => {
  if (noContentOnlyTitle) {
    return (
      <>
        <div className="mb-0 p-4 sm:pl-0 md:flex md:justify-between md:p-0">
          <div className="sm:mb-2">
            <div className="mb-4 flex items-center gap-[6.5px] md:mb-2">
              <div className="mb-2 max-w-[544px]">
                {title && (
                  <Typography className="mb-1" variant="text-heading-md">
                    {title}
                  </Typography>
                )}
                {subtitle && (
                  <Typography className="text-text-grey" variant="text-body-sm">
                    {subtitle}
                  </Typography>
                )}
              </div>
            </div>
          </div>
          {rightTitle ? rightTitle : null}
        </div>
      </>
    );
  }
  if (blurContent) {
    return (
      <>
        {titleOutsideBorder && (
          <div className="md:mt-6">
            <div className="mb-0 p-4 sm:pl-0 md:flex md:justify-between md:p-0">
              <div className="sm:mb-2">
                <div className="mb-4 flex items-center gap-[6.5px] md:mb-2">
                  <div className="mb-2 max-w-[544px]">
                    {title && (
                      <Typography className="mb-1" variant="text-heading-md">
                        {title}
                      </Typography>
                    )}
                    {subtitle && (
                      <Typography
                        className="text-text-grey"
                        variant="text-body-sm"
                      >
                        {subtitle}
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
              {rightTitle ? rightTitle : null}
            </div>
            <div className="relative">
              <div className="rounded-lg bg-secondary-grey-light">
                <div className="p-6">{children}</div>
                {bottomContent ? bottomContent : null}
              </div>
              <div className="absolute left-0 top-0 grid h-full w-full items-start justify-center bg-[#384250]/[0.4] pt-[20%] lg:place-items-center lg:pt-0">
                {blurContent}
              </div>
            </div>
          </div>
        )}
        {!titleOutsideBorder && (
          <div className="relative">
            <div className="rounded-lg bg-secondary-grey-light">
              <div className="p-6">
                <div className="mb-3 md:mb-0 md:flex md:justify-between">
                  <div className="sm:mb-6">
                    <div className="mb-4 flex items-center gap-[6.5px] md:mb-2">
                      <div className="mb-2 max-w-[544px]">
                        {title && (
                          <Typography
                            className="mb-1"
                            variant="text-heading-md"
                          >
                            {title}
                          </Typography>
                        )}
                        {subtitle && (
                          <Typography
                            className="text-text-grey"
                            variant="text-body-sm"
                          >
                            {subtitle}
                          </Typography>
                        )}
                      </div>
                    </div>
                  </div>
                  {rightTitle ? rightTitle : null}
                </div>
                {children}
              </div>
              {bottomContent ? bottomContent : null}
            </div>
            <div className="absolute left-0 top-0 grid h-full w-full items-start justify-center bg-[#384250]/[0.4] pt-[20%] lg:place-items-center lg:pt-0">
              {blurContent}
            </div>
          </div>
        )}
      </>
    );
  }
  return (
    <>
      {titleOutsideBorder && (
        <div className="md:mt-6">
          <div className="mb-0 p-4 sm:pl-0 md:flex md:justify-between md:p-0">
            <div className="sm:mb-2">
              <div className="mb-4 flex items-center gap-[6.5px] md:mb-2">
                <div className="mb-2 max-w-[544px]">
                  <Typography className="mb-1" variant="text-heading-md">
                    {title}
                  </Typography>
                  {subtitle && (
                    <Typography
                      className="text-text-grey"
                      variant="text-body-sm"
                    >
                      {subtitle}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
            {rightTitle ? rightTitle : null}
          </div>
          <div className="rounded-lg border bg-white">
            {topContent ? topContent : null}
            <div className="p-6">{children}</div>
            {bottomContent ? bottomContent : null}
          </div>
        </div>
      )}
      {!titleOutsideBorder && (
        <>
          <div className="rounded-lg border bg-white">
            {topContent ? topContent : null}
            <div className="p-6">
              <div className="mb-3 md:mb-0 md:flex md:justify-between">
                <div className="sm:mb-6">
                  <div className="mb-4 flex items-center gap-[6.5px] md:mb-2">
                    <div className="mb-2 max-w-[544px]">
                      <Typography
                        className="mb-1 text-gray-900"
                        variant="text-heading-md"
                      >
                        {title}
                      </Typography>
                      {subtitle && (
                        <Typography
                          className="text-gray-500"
                          variant="text-body-sm"
                        >
                          {subtitle}
                        </Typography>
                      )}
                    </div>
                  </div>
                </div>
                {rightTitle ? rightTitle : null}
              </div>
              {children}
            </div>
            {bottomContent ? bottomContent : null}
          </div>
        </>
      )}
    </>
  );
};

export default CardWithTitles;

import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useCurrentCompanyProfileUser } from '@/contexts/current-company-profile-user-context';
import useFeatureEnabled, { FLAGS } from '@/hooks/use-feature-toggles';
import routes from '@/utils/routes';

const getABetterRegistryUpsellRoutes = [
  routes.investors.registerOverview.href('[marketListingKey]'),
  routes.investors.shareholderInsights.holdings.href('[marketListingKey]'),
  // we don't use the redirect hook in settings, but just in case
  routes.settings.registry.href('[marketListingKey]'),
] as const;

export const isUpsellingRegistry = (pathname: string) =>
  getABetterRegistryUpsellRoutes.includes(pathname);

export const useRegistryNotSupportedRedirect = (): void => {
  const router = useRouter();
  const isRegistryUpsellEnabled = useFeatureEnabled(FLAGS.registryUpsellPages);
  const { currentCompanyProfileUser, isComputershare, isPremium } =
    useCurrentCompanyProfileUser();
  const { listingKey } = currentCompanyProfileUser.profile.ticker;

  useEffect(() => {
    // Redirection should only happen on base product
    if (!isPremium) {
      // If upsellable do not redirect
      if (
        isComputershare &&
        isRegistryUpsellEnabled &&
        isUpsellingRegistry(router.pathname)
      ) {
        return;
      } else {
        // Otherwise, redirect
        router.push(routes.home.href(listingKey));
      }
    }
  }, [isComputershare, isPremium, isRegistryUpsellEnabled, listingKey, router]);
};
